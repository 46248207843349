import React from 'react';
import './Gallery.css';
import image1 from '../img/security1.jpeg';
import image2 from '../img/gallery1.jpeg';
import image3 from '../img/gallery2.jpeg';
import image4 from '../img/gallery3.jpeg';
import image5 from '../img/gallery4.jpeg';
import image6 from '../img/gallery5.jpeg';
import image7 from '../img/w1.jpeg';
import image8 from '../img/w2.jpeg';
import image9 from '../img/w3.jpeg';
import image10 from '../img/w4.jpeg';
import image11 from '../img/w5.jpeg';
import image12 from '../img/w6.jpeg';
import Footer from '../components/Footer';

import abtHeader from "../img/blog-header.jpg";
import InnerHeaderBanner from "../components/InnerHeaderBanner";
import InnerHeader from "../components/InnerHeader";


const Gallery = () => {
  return (
    <>
     <InnerHeader />
   <InnerHeaderBanner name={"Gallery"} img = {abtHeader}/>
     <div className='gallery2'>
     
    </div>
       <div className='gallery45'>

        
        <div className='gallery1'>
            <img src={image1} />
        </div>
        
        <div className='gallery1'>
            <img src={image2} />
        </div>
        <div  className='gallery1'>
            <img src={image3} />
        </div>
       
        
    </div>
    <div className='gallery45'>
        <div className='gallery1'>
            <img src={image4} />
        </div>
        
        <div className='gallery1'>
            <img src={image5} />
        </div>
        <div  className='gallery1'>
            <img src={image6} />
        </div>
       
        
    </div>


    <div className='gallery45'>

        
        <div className='gallery1'>
            <img src={image7} />
        </div>
        
        <div className='gallery1'>
            <img src={image8} />
        </div>
        <div  className='gallery1'>
            <img src={image9} />
        </div>
       
        
    </div>


    <div className='gallery45'>

        
        <div className='gallery1'>
            <img src={image10} />
        </div>
        
        <div className='gallery1'>
            <img src={image11} />
        </div>
        <div  className='gallery1'>
            <img src={image12} />
        </div>
       
        
    </div>
    <Footer/>

    <br/>
    <br/>
    </>
  )
}

export default Gallery